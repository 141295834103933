import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '../../../utility/common.helper.utility';
export default {
  name: 'tagComm',
  components: { DatePicker },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      editMode: false,
      showAlert: false,
      showTdsInvoiceModal: false,
      showValueSetModal: false,
      isSuccess: false,
      responseMsg: '',
      tagType: {
        value: null,
        text: null
      },
      tagStatus: {
        value: null,
        text: null
      },
      serverEmail: null,
      fromDate: null,
      toDate: null,
      email: null,
      subject: null,
      defaultValue: { text: null, value: null },
      tagCommData: [],
      tagCommFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'from'
        },
        {
          key: 'subject'
        },
        {
          key: 'cc',
          label: 'CC'
        },
        {
          key: 'received_date'
        },
        {
          key: 'tag'
        },
        {
          key: 'mail'
        },
        {
          key: 'query'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    getTagCommList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getTagCommList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.tagCommData = resp.data.data.page;
            this.tagCommData.map(data => {
              data.tag = 'Link';
              data.mail = 'Link';
              data.query = 'Link';
              return data;
            });
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideTdsInvoiceModal(flag) {
      this.showTdsInvoiceModal = flag;
    },
    rowSelected(item) {
      this.invoiceDetails = item; // For Props
      this.showTdsInvoiceModal = true; // For Open Child Modal
    },
    openValueSetModal(vsetCode) {
      // To Open Value Set Modal
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      // To Selected Value from value set modal
      if (this.vsetCode === appStrings.VALUESETTYPE.CC_TAG_TYPE) {
        this.tagType.text = item.value_meaning;
        this.tagType.value = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.EMAIL_TAGGED_STATUS
      ) {
        this.tagStatus.text = item.value_meaning;
        this.tagStatus.value = item.value_code;
      }
    },
    clearFilters() {
      this.tagType = this.defaultValue;
      this.tagStatus = this.defaultValue;
      this.fromDate = null;
      this.toDate = null;
      this.email = null;
      this.subject = null;
      this.tagCommData = [];
      this.totalRows = null;
      this.currentPage = 1;
    }
  }
};
